import React from "react";
import "../components CSS/FullScreenImg.css";

export default function FullScreenImg({
  fullscreenImgSrc,
  setFullscreenImgSrc,
}) {
  function handleCloseClick(e) {
    if (
      e.target.classList.contains("fullscreen-overlay") ||
      e.target.classList.contains("close-buttom") ||
      e.target.classList.contains("close-icon")
    ) {
      setFullscreenImgSrc(null);
    }
  }
  return (
    <div className="fullscreen-overlay " onClick={handleCloseClick}>
      <div className="close-button" onClick={handleCloseClick}>
        <div className="close-icon"></div>
      </div>
      <div className="fullscreen-container">
        <img
          src={fullscreenImgSrc}
          alt="Fullscreen"
          className="fullscreen-image"
        />
      </div>
    </div>
  );
}
