import React, { useState, useEffect } from "react";
import * as contentful from "contentful";
import Project from "./Project";
import "../components CSS/Projects.css";

export default function Projects({ setFullscreenImgSrc }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const client = contentful.createClient({
      space: "eu57fu0xi2p9",
      environment: "master",
      accessToken: "qoSAKhiqiUcebQIb2UYvQ0XjgTC54IO2sa0IxFUMm-U",
    });

    const fetchProjects = async () => {
      try {
        const entries = await client.getEntries();

        const newProjects = entries.items.map((item) => {
          const { title, description, screenshots, links } = item.fields;

          const screenshotIds = screenshots.map(
            (screenshot) => screenshot.sys.id
          );

          const screenshotUrls = screenshotIds.map((id) => {
            const screenshotAsset = entries.includes.Asset.find(
              (asset) => asset.sys.id === id
            );
            return screenshotAsset.fields.file.url;
          });

          return {
            title,
            description,
            screenshotUrls,
            links,
          };
        });

        setProjects(newProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="projects">
      {projects.map((project, index) => (
        <Project
          key={index}
          project={project}
          setFullscreenImgSrc={setFullscreenImgSrc}
        />
      ))}
    </div>
  );
}
