import React from "react";
import emailIcon from "../images/white-email.png";

const EmailButton = () => {
  const handleClick = () => {
    const one = ["taymen", "iinet"].join("@");
    const Bee = ["net", "au"].join(".");
    const orange = [one, Bee];

    window.location.href = `mailto:${orange.join(".")}`;
  };

  return (
    <a target="_blank">
      <img src={emailIcon} alt="message" onClick={handleClick} />
    </a>
  );
};

export default EmailButton;
