import "../components CSS/About.css";
export default function About() {
  return (
    <section className="about">
      <h2>About me</h2>
      <p>
        I'm Taymen, a junior software developer based in Perth, Western
        Australia. My journey into the world of software engineering has been
        quite unique, having come from a background in mechanics. Completing the
        immersive software engineering program at General Assembly marked my
        exciting transition, where my love for learning and adept
        problem-solving skills found a new avenue. <br />
        <br /> My years as a mechanic have instilled a mindset that I now bring
        into software development—an enthusiasm for collaborative efforts and a
        well-balanced mix of technical expertise and adaptability. With a
        commitment to excellence and analytical thinking refined in my previous
        role, I consider myself a valuable asset in any team. <br />
        <br /> I am eager to contribute my skills and passion to the dynamic
        field of software development. The prospect of creating meaningful and
        innovative solutions is truly thrilling to me. Let's embark on the
        journey of building something great together!
      </p>
    </section>
  );
}
