import { useState } from "react";
import "./App.css";
import About from "./components/About";
import FullScreenImg from "./components/FullScreenImg";
import Landing from "./components/Landing";
import Projects from "./components/Projects";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [fullscreenImgSrc, setFullscreenImgSrc] = useState(null);

  return (
    <div className="App">
      <Landing />
      <About />
      <Projects setFullscreenImgSrc={setFullscreenImgSrc} />
      {fullscreenImgSrc && (
        <FullScreenImg
          fullscreenImgSrc={fullscreenImgSrc}
          setFullscreenImgSrc={setFullscreenImgSrc}
        />
      )}
      <Analytics />
    </div>
  );
}

export default App;
