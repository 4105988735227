import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../components CSS/Project.css";
import githubLogoBlack from "../images/github-black.512x500-2.png";
import earthLogo from "../images/earth.512x512.png";

export default function Project({ project, setFullscreenImgSrc }) {
  return (
    <section className="project">
      <div className="left">
        {project.screenshotUrls.length > 1 ? (
          <Carousel
            autoPlay
            infiniteLoop
            interval={Math.floor(Math.random() * (3201 - 2500)) + 2500}
            swipeable={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            onClickItem={(i, img) =>
              setFullscreenImgSrc(project.screenshotUrls[i])
            }
          >
            {project.screenshotUrls.map((url, index) => (
              <div key={index}>
                <img src={url} alt={`Screenshot ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        ) : (
          <img
            onClick={() => setFullscreenImgSrc(project.screenshotUrls[0])}
            src={project.screenshotUrls[0]}
            alt="Project screenshot"
          />
        )}
      </div>
      <div className="right">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <div className="links">
          {project.links &&
            project.links.map((link, index) => (
              <a
                key={index}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    link.includes("github.com") ? githubLogoBlack : earthLogo
                  }
                  alt=""
                />
                {link.includes("github.com") ? "GitHub" : "Web"}
              </a>
            ))}
        </div>
      </div>
    </section>
  );
}
