import React, { useEffect, useState } from "react";
import background from "../images/IMG_0360.jpeg";
import githubLogo from "../images/github.512x499.png";
import linkedinLogo from "../images/linkedin.512x512.png";
import "../components CSS/Landing.css";
import EmailButton from "./EmailButton";

export default function Landing() {
  const [isHeaderOpaque, setHeaderOpaque] = useState(false);
  const [isNavOpaque, setNavOpaque] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const titleElement = document.getElementById("main-title");
      const navElement = document.querySelector(".navigation");

      setHeaderOpaque(offset > titleElement.offsetTop + 10);
      setNavOpaque(offset > navElement.offsetTop - 30);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`title-page `}>
      <header className={`${isHeaderOpaque ? "visible-header" : ""}`}>
        <div
          className={`${isHeaderOpaque ? "visible-h3" : ""} taymen-verschuren`}
        >
          <h3 className="taymen">TAYMEN</h3>
          <h3 className="verschuren">VERSCHUREN</h3>
        </div>
        <nav className={`${isNavOpaque ? "visible-nav" : ""}`}>
          <a
            href="https://www.linkedin.com/in/taymenverschuren/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinLogo} alt="" className="linkedin-logo" />
          </a>
          <a
            href="https://github.com/Taymen30"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={githubLogo} alt="" className="github-logo" />
          </a>
          <EmailButton />
        </nav>
      </header>
      <div className="top">
        <div className={`text ${isHeaderOpaque ? "visible-text" : ""}`}>
          <h1 id="main-title">TAYMEN VERSCHUREN</h1>
          <h2>FULL STACK DEVELOPER</h2>
        </div>
        <div className={`${isNavOpaque ? "hidden-nav" : ""} navigation`}>
          <a
            href="https://www.linkedin.com/in/taymenverschuren/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinLogo} alt="" className="linkedin-logo" />
          </a>
          <a
            href="https://github.com/Taymen30"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={githubLogo} alt="" className="github-logo" />
          </a>
          <EmailButton />
        </div>
      </div>
      <div className="background">
        <img src={background} alt="" className="image-taymen" />
      </div>
    </div>
  );
}
